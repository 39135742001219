import React, { FC } from "react"

import { device } from "../../styles/media"
import facebookIcon from "../../images/icons/facebook.svg"
import styled from "styled-components"

const FooterContainer = styled.footer`
  width: 100%;
  height: 72px;
  background: #010101;
  padding: 0 120px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  position: absolute;
  bottom: 0;
  @media ${device.laptop} {
    padding: 0 50px;
  }
  @media ${device.mobileL} {
    height: auto;
    padding: 20px;
    flex-direction: column;
  }
  .address {
    @media ${device.mobileL} {
      max-width: 170px;
      text-align: center;
      margin-bottom: 8px;
    }
  }
  .contacts {
    display: flex;
    align-items: center;
    @media ${device.mobileL} {
      height: auto;
      align-items: center;
      flex-direction: column;
      p {
        margin-bottom: 8px;
      }
      p:first-child {
        margin-right: 0;
      }
    }
    a {
      text-decoration: none;
      color: #fff;
    }
    p:not(:last-child) {
      margin-right: 38px;
      @media ${device.mobileL} {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
`

const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
`

export const Footer: FC<{
  address: string
  email: string
  phoneNumber: string
}> = ({ address, email, phoneNumber }) => {
  return (
    <FooterContainer>
      <p className="address">{address}</p>
      <div className="contacts">
        <p className="mail">
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p className="phone">
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </p>
        <p>
          <a
            target="blank"
            href="https://www.facebook.com/%D0%90%D0%B4%D0%B2%D0%BE%D0%BA%D0%B0%D1%82%D1%81%D1%8C%D0%BA%D0%B5-%D0%BE%D0%B1%D1%94%D0%B4%D0%BD%D0%B0%D0%BD%D0%BD%D1%8F-%D0%9B%D0%B5%D0%BA%D1%81-%D0%90%D0%BB%D1%8C%D1%8F%D0%BD%D1%81-100284148823804"
          >
            <SocialIcon src={facebookIcon} />
          </a>
        </p>
      </div>
    </FooterContainer>
  )
}
