import { Link, navigate } from "gatsby"
import React, { FC, useMemo, useRef, useState } from "react"

import { LanguageSelect } from "./language-select"
import { Modal } from "./Modal"
import closeWhite from "../../images/close-white.svg"
import { device } from "../../styles/media"
import langImg from "../../images/lang.png"
import logo from "../../images/logo.svg"
import styled from "styled-components"

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 45px;
  width: 100vw; ;
`
const BurgerButton = styled.img`
  position: absolute;
  right: 20px;
  top: -5px;
  display: none;
  cursor: pointer;
  @media ${device.mobileL} {
    display: block;
  }
`
const Logo = styled.img`
  width: 40px;
  height: 40px;
  @media ${device.mobileL} {
    margin-bottom: 8px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`

const LogoTitle = styled.h1`
  color: #fff;
  font-weight: bold;
  font-style: normal;
  font-family: "Helvetica", sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-left: 8px;
`
const HeaderNav = styled.nav<{ isOpened: boolean }>`
  display: none;
  width: ${props => (props.isOpened ? "100vw" : 0)};
  transition: all 0.5s;
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  left: 0;
  top: 0;
  background: #334061;
  overflow: hidden;
  z-index: 10;
  @media ${device.mobileL} {
    display: flex;
  }
`

const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  .language-selector {
    width: 20px;
    height: 20px;
  }
`

const NavigationLink = styled.li`
  margin-left: 0;
  cursor: pointer;
  margin-bottom: 28px;
  .link {
    text-decoration: none;
    color: #fff;
    &-active {
      text-decoration-line: line-through;
      font-weight: bold;
    }
  }
`

export const BurgerNavigation: FC<{
  lang: string
  navigationItems?: {
    about: string
    blog: string
    contacts: string
    team: string
  }
  addressCoordinates?: {
    lat: number
    lng: number
  }
  contactUsTitle: string
  address: string
  phoneNumber: string
  isNavOpened: boolean
  emailAddress: string
  setClose: () => void
}> = ({
  lang,
  navigationItems,
  addressCoordinates,
  address,
  phoneNumber,
  contactUsTitle,
  isNavOpened,
  setClose,
  emailAddress,
}) => {
  const langSelectRef = useRef(null)
  const links = useMemo(() => {
    return [
      {
        title: navigationItems?.about || "О нас",
        href: `/about`,
      },
      {
        title: navigationItems?.team || "Команда",
        href: `/team`,
      },
      // {
      //   title: navigationItems?.blog || "Блог",
      //   href: `/blog`,
      // },
    ]
  }, [])

  const [langData, setLangData] = useState(lang)
  const [isModalOpened, setIsMopdalOpened] = useState<boolean>(false)
  const [selectorOpened, setSelectorOpened] = useState({
    opened: false,
    left: 0,
    top: 0,
  })

  const setLanguage = lang => {
    navigate(
      window.location.pathname
        .split("/")
        .map((item, index) => {
          if (index === 1) {
            item = lang
          }
          return item
        })
        .join("/")
    )
    setLangData(lang)
  }

  return (
    <HeaderNav isOpened={isNavOpened}>
      <AbsoluteContainer>
        <Link
          to={`/${lang}/`}
          style={{
            textDecoration: "none",
          }}
        >
          <LogoWrapper>
            <Logo src={logo} alt="Lex aliance" />
            <LogoTitle>Лекс Альянс</LogoTitle>
          </LogoWrapper>
        </Link>
        <BurgerButton src={closeWhite} onClick={setClose}></BurgerButton>
      </AbsoluteContainer>
      <NavigationList>
        {links.map(link => (
          <NavigationLink key={link.title}>
            <Link
              to={`/${lang}${link.href}`}
              className="link"
              activeClassName="link-active"
            >
              {link.title}
            </Link>
          </NavigationLink>
        ))}
        <NavigationLink onClick={() => setIsMopdalOpened(!isModalOpened)}>
          <a className="link">{navigationItems?.contacts || "Контакты"}</a>
        </NavigationLink>
        {selectorOpened.opened &&
          LanguageSelect({
            setLanguage,
            top: selectorOpened.top,
            left: selectorOpened.left,
          })}
        {isModalOpened &&
          Modal({
            clickOverlay: () => setIsMopdalOpened(false),
            addressCoordinates,
            adress: address,
            phoneNumber,
            contactUsTitle,
            emailAddress: emailAddress,
          })}
        <img
          ref={langSelectRef}
          className="language-selector"
          src={langImg}
          onClick={() => {
            setSelectorOpened({
              opened: !selectorOpened.opened,
              top: langSelectRef.current.offsetTop,
              left: langSelectRef.current.offsetLeft,
            })
          }}
        ></img>
      </NavigationList>
    </HeaderNav>
  )
}
