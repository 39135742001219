import {
  GoogleApiWrapper,
  IMapProps,
  InfoWindow,
  Map,
  Marker,
} from "google-maps-react"
import React, { Component, FC, useEffect } from "react"

import closeIcon from "../../images/close.svg"
import { createPortal } from "react-dom"
import { device } from "../../styles/media"
import facebookIcon from "../../images/icons/facebook-black.svg"
import styled from "styled-components"

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
]

function canUseDOM() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  )
}

export class MapContainer extends Component<
  IMapProps & {
    addressCoordinates?: {
      lat: number
      lng: number
    }
  }
> {
  render() {
    return (
      <Map
        google={this.props.google}
        onReady={(props, map) =>
          map.setOptions({
            styles: mapStyle,
            disableDefaultUI: true,
          })
        }
        zoom={15}
        initialCenter={{
          lat: this.props.addressCoordinates?.lat || 50.468608,
          lng: this.props.addressCoordinates?.lng || 30.518441,
        }}
      >
        <Marker
          mapCenter={{
            lat: this.props.addressCoordinates?.lat || 50.468608,
            lng: this.props.addressCoordinates?.lng || 30.518441,
          }}
          position={{
            lat: this.props.addressCoordinates?.lat || 50.468608,
            lng: this.props.addressCoordinates?.lng || 30.518441,
          }}
        ></Marker>
      </Map>
    )
  }
}

const MapView = GoogleApiWrapper({
  apiKey: "AIzaSyB5K4ISdZJe-IbILzK15PLqUkivuxoE20M",
})(MapContainer)

const ModalOverlayContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 12;
  justify-content: center;
`

const ModalContentContainer = styled.div`
  width: 1050px;
  height: 640px;
  background: #ffffff;
  padding: 40px;
  position: relative;
  @media ${device.mobileL} {
    width: 100%;
    height: 100vh;
    padding: 40px 0;
  }
`

const MapContainerStyle = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  @media ${device.mobileL} {
    height: 500px;
  }
`

const CloseIcon = styled.img`
  width: 23px;
  height: 23px;
  position: absolute;
  right: 40px;
  top: 48px;
  cursor: pointer;
  @media ${device.mobileL} {
    right: 25px;
    top: 20px;
  }
`
const ModalTitle = styled.h4`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  @media ${device.mobileL} {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  }
`

const ModalTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media ${device.mobileL} {
    padding: 20px;
  }
`

const ContectField = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  @media ${device.mobileL} {
    text-align: center;
  }
`

let portalContainer
if (canUseDOM()) {
  portalContainer = document.createElement("div")
  document.querySelector("body").appendChild(portalContainer)
}

export const Modal = ({
  clickOverlay,
  adress,
  contactUsTitle,
  phoneNumber,
  addressCoordinates,
  emailAddress,
}: {
  clickOverlay: () => void
  adress: string
  contactUsTitle?: string
  phoneNumber?: string
  emailAddress?: string
  addressCoordinates?: {
    lat: number
    lng: number
  }
}) => {
  if (!portalContainer) return null
  return createPortal(
    <ModalOverlayContainer
      onClick={e => {
        e.stopPropagation()
        if (
          Array.from((e.target as HTMLElement).classList).find(item =>
            item?.includes("ModalOverlayContainer")
          )
        ) {
          clickOverlay()
        }
      }}
    >
      <ModalContentContainer>
        <CloseIcon src={closeIcon} onClick={() => clickOverlay()}></CloseIcon>
        <ModalTitleContainer>
          <ModalTitle>{contactUsTitle}</ModalTitle>
          <ContectField>{adress}</ContectField>
          <ContectField>
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </ContectField>
          <ContectField>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </ContectField>
          <ContectField>
            <a
              target="blank"
              href="https://www.facebook.com/%D0%90%D0%B4%D0%B2%D0%BE%D0%BA%D0%B0%D1%82%D1%81%D1%8C%D0%BA%D0%B5-%D0%BE%D0%B1%D1%94%D0%B4%D0%BD%D0%B0%D0%BD%D0%BD%D1%8F-%D0%9B%D0%B5%D0%BA%D1%81-%D0%90%D0%BB%D1%8C%D1%8F%D0%BD%D1%81-100284148823804"
            >
              <img src={facebookIcon}></img>
            </a>
          </ContectField>
        </ModalTitleContainer>

        <MapContainerStyle>
          <MapView addressCoordinates={addressCoordinates}></MapView>
        </MapContainerStyle>
      </ModalContentContainer>
    </ModalOverlayContainer>,
    portalContainer
  )
}
