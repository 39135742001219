import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: Montserrat, sans-serif;
    position: relative;
    min-height: 100vh;
  }
  .description-container {
    flex: 1 0 100%;
  }
`;