import { Link, navigate } from "gatsby"
import React, { FC, useMemo, useRef, useState } from "react"

import { LanguageSelect } from "./language-select"
import langImg from "../../images/lang.png"
import styled from "styled-components"
import { Modal } from "./Modal"
import { device } from "../../styles/media"

const HeaderNav = styled.nav`
  display: flex;
  @media ${device.mobileL} {
    display: none;
  }
`

const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  .language-selector {
    width: 20px;
    height: 20px;
    margin-left: 38px;
  }
`

const NavigationLink = styled.li`
  margin-left: 28px;
  cursor: pointer;
  margin-bottom: 0;
  :first-child {
    margin-left: 0;
  }
  .link {
    text-decoration: none;
    color: #fff;
    &-active {
      text-decoration-line: line-through;
      font-weight: bold;
    }
  }
`

export const HeaderNavigation: FC<{
  lang: string
  navigationItems?: {
    about: string
    blog: string
    contacts: string
    team: string
  }
  addressCoordinates?: {
    lat: number
    lng: number
  }
  contactUsTitle: string
  address: string
  phoneNumber: string
  emailAddress: string
}> = ({
  lang,
  navigationItems,
  addressCoordinates,
  address,
  phoneNumber,
  contactUsTitle,
  emailAddress,
}) => {
  const langSelectRef = useRef(null)
  const links = useMemo(() => {
    return [
      {
        title: navigationItems?.about || "О нас",
        href: `/about`,
      },
      {
        title: navigationItems?.team || "Команда",
        href: `/team`,
      },
      // {
      //   title: navigationItems?.blog || "Блог",
      //   href: `/blog`,
      // },
    ]
  }, [])

  const [langData, setLangData] = useState(lang)
  const [isModalOpened, setIsMopdalOpened] = useState<boolean>(false)
  const [selectorOpened, setSelectorOpened] = useState({
    opened: false,
    left: 0,
    top: 0,
  })

  const setLanguage = lang => {
    navigate(
      window.location.pathname
        .split("/")
        .map((item, index) => {
          if (index === 1) {
            item = lang
          }
          return item
        })
        .join("/")
    )
    setLangData(lang)
  }

  return (
    <HeaderNav>
      <NavigationList>
        {links.map(link => (
          <NavigationLink key={link.title}>
            <Link
              to={`/${lang}${link.href}`}
              className="link"
              activeClassName="link-active"
            >
              {link.title}
            </Link>
          </NavigationLink>
        ))}
        <NavigationLink onClick={() => setIsMopdalOpened(!isModalOpened)}>
          <a className="link">{navigationItems?.contacts || "Контакты"}</a>
        </NavigationLink>
        {selectorOpened.opened &&
          LanguageSelect({
            setLanguage,
            top: selectorOpened.top,
            left: selectorOpened.left,
          })}
        {isModalOpened &&
          Modal({
            clickOverlay: () => setIsMopdalOpened(false),
            addressCoordinates,
            adress: address,
            phoneNumber,
            contactUsTitle,
            emailAddress: emailAddress,
          })}
        <img
          ref={langSelectRef}
          className="language-selector"
          src={langImg}
          onClick={() => {
            setSelectorOpened({
              opened: !selectorOpened.opened,
              top: langSelectRef.current.offsetTop,
              left: langSelectRef.current.offsetLeft,
            })
          }}
        ></img>
      </NavigationList>
    </HeaderNav>
  )
}
