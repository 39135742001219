import React, { FC, useEffect } from "react"

import { createPortal } from "react-dom"
import styled from "styled-components"

function canUseDOM() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  )
}

const ListContainer = styled.div<{ top: number; left: number }>`
  width: 200px;
  background: #fff;
  position: fixed;
  padding: 0 20px;
  top: ${props => props.top + 60}px;
  left: ${props => props.left - 100}px;
  z-index: 4;
`
const LanguaageList = styled.ul`
  list-style: none;
`
const LanguageListItem = styled.li`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px 0;
  &:hover {
    color: #6cbcde;
  }
`
let portalContainer
if (canUseDOM()) {
  portalContainer = document?.createElement("div")
  document?.querySelector("body").appendChild(portalContainer)
}

export const LanguageSelect = ({
  setLanguage,
  top,
  left,
}: {
  setLanguage: (lang: string) => void
  top: number
  left: number
}) => {
  if (!portalContainer) return null
  return createPortal(
    <ListContainer top={top} left={left}>
      <LanguaageList>
        <LanguageListItem onClick={() => setLanguage("en")}>
          English
        </LanguageListItem>
        <LanguageListItem onClick={() => setLanguage("ru")}>
          Русский
        </LanguageListItem>
        <LanguageListItem onClick={() => setLanguage("ua")}>
          Українська
        </LanguageListItem>
      </LanguaageList>
    </ListContainer>,
    portalContainer
  )
}
