import React, { FC, useCallback, useState } from "react"

import { BurgerNavigation } from "./BurgerNavigation"
import { Container } from "../shared"
import { HeaderNavigation } from "./header-navigation"
import { Link } from "gatsby"
import burder from "../../images/burger.svg"
import { device } from "../../styles/media"
import logo from "../../images/logo.svg"
import styled from "styled-components"

const Logo = styled.img`
  width: 40px;
  height: 40px;
  @media ${device.mobileL} {
    margin-bottom: 8px;
  }
`

const BurgerButton = styled.img`
  position: absolute;
  right: 20px;
  top: 10px;
  display: none;
  cursor: pointer;
  @media ${device.mobileL} {
    display: block;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`

const LogoTitle = styled.h1`
  color: #fff;
  font-weight: bold;
  font-style: normal;
  font-family: "Helvetica", sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-left: 8px;
`

const TopHeader = styled.header`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100px;
  padding: 30px 175px 30px 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 1;
  @media ${device.laptop} {
    padding: 30px 50px;
  }

  @media ${device.mobileL} {
    align-items: center;
    justify-content: center;
  }
`

export const Header: FC<{
  lang: string
  navigationItems?: {
    about: string
    blog: string
    contacts: string
    team: string
  }
  addressCoordinates?: {
    lat: number
    lng: number
  }
  contactUsTitle: string
  address: string
  phoneNumber: string
  emailAddress: string
}> = ({
  lang,
  navigationItems,
  addressCoordinates,
  contactUsTitle,
  address,
  phoneNumber,
  emailAddress,
}) => {
  const [isNavOpened, setIsNavOpened] = useState<boolean>(false)
  const setClose = useCallback(() => setIsNavOpened(false), [])
  return (
    <TopHeader>
      <Link
        to={`/${lang}/`}
        style={{
          textDecoration: "none",
        }}
      >
        <LogoWrapper>
          <Logo src={logo} alt="Lex Alliance" />
          <LogoTitle>
            {lang === "en" ? "Lex Alliance" : "Лекс Альянс"}
          </LogoTitle>
        </LogoWrapper>
      </Link>
      <BurgerButton
        src={burder}
        onClick={() => setIsNavOpened(!isNavOpened)}
      ></BurgerButton>
      <HeaderNavigation
        lang={lang}
        navigationItems={navigationItems}
        addressCoordinates={addressCoordinates}
        phoneNumber={phoneNumber}
        address={address}
        contactUsTitle={contactUsTitle}
        emailAddress={emailAddress}
      />
      <BurgerNavigation
        lang={lang}
        navigationItems={navigationItems}
        addressCoordinates={addressCoordinates}
        phoneNumber={phoneNumber}
        address={address}
        contactUsTitle={contactUsTitle}
        isNavOpened={isNavOpened}
        setClose={setClose}
        emailAddress={emailAddress}
      />
    </TopHeader>
  )
}
