/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "normalize.css"
import "reset.css"
import "./layout.css"
import { Footer } from "./footer/footer"
import GlobalStyles from "../styles/global"
import { Header } from "./header/index"
import React, { FC } from "react"
import SEO from "./seo"
import styled from "styled-components"
import { device } from "../styles/media"

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  margin: auto;
  padding-bottom: 72px;
  position: relative;
  @media ${device.mobileL} {
    padding-bottom: 130px;
  }
`

const Layout: FC<{
  lang: string
  address: string
  phoneNumber: string
  email: string
  navigationItems?: {
    about: string
    blog: string
    contacts: string
    team: string
  }
  addressCoordinates: {
    lat: number
    lng: number
  }
  contactUsTitle: string
}> = ({
  lang,
  children,
  address,
  phoneNumber,
  email,
  navigationItems,
  contactUsTitle,
  addressCoordinates,
}) => {
  return (
    <>
      <SEO title={"Лекс Альянс"} />
      <GlobalStyles />
      <Wrapper>
        <Header
          lang={lang}
          navigationItems={navigationItems}
          address={address}
          phoneNumber={phoneNumber}
          contactUsTitle={contactUsTitle}
          addressCoordinates={addressCoordinates}
          emailAddress={email}
        />
        <main>{children}</main>
      </Wrapper>
      <Footer address={address} phoneNumber={phoneNumber} email={email} />
    </>
  )
}

export default Layout
